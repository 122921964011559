<!-- 管理员管理 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<script>
// import Multiselect from "vue-multiselect";

/**
 * Dashboard component
 */
export default {
  props:{
    title: String,
    bound: Array,
  },
  components: {
    // Multiselect
  },
  model:{
    event: "input",
    data: "form"
  },
  data() {
    return {
      showHandle: false,
      form: {},
    };
  },
  methods: {
    handle(status){
      let yj = this.form[this.bound[1]]
      this.form = {}
      if (!yj){
        yj=(status==1)?'人工审核通过':'人工审核未通过'
      }
      this.form[this.bound[0]] = status
      this.form[this.bound[1]] = yj
      this.$emit("input", this.form)
      this.$emit("change", this.form)
    },
    show(){
      this.form = {}
      this.showHandle = true
    },
    hide(){
      this.showHandle = false
    }
  },
  mounted() {

  },
};
</script>

<template>
  <b-modal
        v-model="showHandle"
        centered
        :title="title"
        size="lg"
        title-class="font-18"
        hide-footer
    >
    <div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0" style="width: 10%">审核意见：</label>
        <div class="col-sm-10 p-0 flexList">
          <textarea v-model="form[bound[1]]" id="" class="form-control w-100" rows="5"></textarea>
        </div>
      </div>
    </div>
      <div class="mt-3 text-center">
        <button
            type="button"
            class="btn btn-info h30 w-md mr-3"
            @click="handle(1)"
        >
          通过
        </button>
        <button
            type="button"
            class="btn btn-danger h30 w-md mr-3"
            @click="handle(2)"
        >
          不通过
        </button>
        <button
            type="button"
            class="btn btn-secondary h30 w-md"
            @click="showHandle = false"
        >
          取消
        </button>
      </div>
    </b-modal>
</template>
