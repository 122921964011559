<script>
import {getHandles} from "@/api/userInfo_user";

export default {
  data() {
    return {
      checked: true,
      checked2: false,
      isActive: "",
      ishref: "",
      handles: {
        handleUserInfo: {
          shbz: "",
          shrxm: "",
          shrzh: "",
          shsj: "",
          shzt: 1,
          xm: "",
          sfzjh: "",
        },
        userInfoPhoto: {
          "bzzxthyzt":0,
          "ldzxthyzt":0,
          "hdzxthyzt":0,
          "sfztxhyzt":0,
          "zjshzt":0,
        },
        userInfoEducation:[],
      }
    }
  },
  methods: {
    getHandles() {
      getHandles(this.$route.query.sfzjh).then(res => {
        if (res.status) {
          this.handles = res.data
        }
      })
    },
    init(){
      this.getHandles()
    }
  },
  computed: {
    photoOk(){
      return !['bzzshzt', 'ldzshzt', 'hdzshzt', 'zjshzt'].some(item=>{
        return this.handles.userInfoPhoto && this.handles.userInfoPhoto[item] != 1
      })
    },
    eduOk(){
      return !this.handles.userInfoEducation.some(item=>{
        return item.xlshzt != 1
      })
    }
  },
  mounted() {
    this.init()
    this.isActive = window.location.pathname
    this.ishref = window.location.href
  }
};
</script>

<style>
</style>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title pb-3 border-dash">
            <span class="mr-2">姓名：{{handles.handleUserInfo.xm}}</span> <span class="mr-2">证件号码：{{handles.handleUserInfo.sfzjh}} </span> <span
              class="mr-2">个人信息</span>
          </h4>
          <div class="flexList person-header">
            <div class="d-flex w-20">
              <div class="data-tips flexList" :class="{'active': this.$route.name=='personalData'}">
                <i class="data-tips-icon mr-2 iconfont icon-analysis"></i>
                <a :href="'/admin/personalData?sfzjh=' + this.$route.query.sfzjh">
                  <div class="tip-font">
                    <div class="fw600">基本资料</div>
                    <div class="font12 text-success" v-if="handles.handleUserInfo.shzt == 1">审核状态：已审核</div>
                    <div class="font12 text-danger" v-else-if="handles.handleUserInfo.shzt == 2">审核状态：审核未通过</div>
                    <div class="font12 text-danger" v-else>审核状态：待审核</div>
                  </div>
                </a>
              </div>
              <img src="@/assets/images/person/double-right.png" class="arrow-img">
            </div>

            <div class="d-flex w-20 ">
              <div class="data-tips flexList" :class="{'active': this.$route.name=='personalImage'}">
                <i class="data-tips-icon mr-2 iconfont icon-picture-one"></i>
                <a :href="'/admin/personalImage?sfzjh=' + this.$route.query.sfzjh">
                <div class="tip-font">
                  <div class="fw600">照片认证</div>
                  <div class="font12 text-success" v-if="photoOk">认证状态：已认证</div>
                  <div class="font12 text-danger" v-else>认证状态：待认证</div>
                </div>
                </a>
              </div>
              <img src="@/assets/images/person/double-right.png" class="arrow-img">
            </div>
            <div class="d-flex w-20 ">
              <div class="data-tips flexList" :class="{'active': this.$route.name=='personalEducation'}">
                <i class="data-tips-icon mr-2 iconfont icon-certificate"></i>
                <a :href="'/admin/personalEducation?sfzjh=' + this.$route.query.sfzjh">
                  <div class="tip-font">
                    <div class="fw600">学历信息认证</div>
                    <div class="font12 text-success" v-if="eduOk">认证状态：已认证</div>
                    <div class="font12 text-danger" v-else>认证状态：待认证</div>
                  </div>
                </a>
              </div>
              <img src="@/assets/images/person/double-right.png" class="arrow-img">
            </div>
            <div class="d-flex w-20">
              <div class="data-tips flexList" :class="{'active': this.$route.name=='personalWork'}">
                <i class="data-tips-icon mr-2 iconfont icon-if-architecture-alt"></i>
                <a :href="'/admin/personalWork?sfzjh=' + this.$route.query.sfzjh">
                  <div class="tip-font">
                    <div class="fw600">工作经历</div>
                  </div>
                </a>
              </div>
              <img src="@/assets/images/person/double-right.png" class="arrow-img">
            </div>
            <div class="d-flex w-20 ">
              <div class="data-tips flexList">
                <i class="data-tips-icon mr-2 iconfont icon-a-md-lock_outlineCopy"></i>
                <div class="tip-font">
                  <div class="fw600">账号信息</div>
                  <div class="font12">重置密码、认证手机号等</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>
