<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.time_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<script>
import $ from "jquery";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PersonHeader from "@/views/pages/admin/user/examinee/person-header.vue";
import { regionData } from "element-china-area-data"; //省市区不带'全部'的三级联动
import YzModal from '@/components/form/yzModal.vue'
import {
  handleChange,
  changeOption,
  getDictionary,
  boundDefaultData,
} from "@/libs/common_user";

import { getUserEdu, updateUserEdu, deleteUserEdu } from "@/api/userInfo_user.js";
import {handleUserEdu} from "@/api/userInfo_user";
import {userInfo} from "@/api/userInfo_user";
/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
    YzModal,
  },
  data() {
    const checkZY = (rule, value, cb) => {
      if (this.zyShow && !this.EduactionForm.sxzym) {
        return cb(new Error("请选择所学专业"));
      }
      cb();
    };
    const checkXW = (rule, value, cb) => {
      if (this.xwShow && !this.EduactionForm.hdxwm) {
        return cb(new Error("请选择学位"));
      }
      cb();
    };
    const checkXWH = (rule, value, cb) => {
      if (this.xwShow && !this.EduactionForm.xwzsh) {
        return cb(new Error("请输入学位证书号"));
      }
      cb();
    };
    return {
      title: "个人资料完善",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "学历信息",
          active: true,
        },
      ],
      page: "edu",
      xwShow: false,
      zyShow: false,
      modalText: "",
      modalBtnText: "",
      options: regionData, //省市区数据
      pickerOptions_start: "",
      pickerOptions_end: "",
      sjzd: {},
      edit: {},
      eduList: [],
      showmodal: false,
      value1: "",
      EduactionForm: {
        rxny: "",
        jsxyny: "",
        xxfsmc: "",
        xxfsm: "",
        xxxsmc: "",
        xxxsm: "",

        gjdqm: "",
        gjdqmc: "",
        byyxxhdw: "",
        sxzym: "",
        sxzymc: "",
        xlmc: "",
        xlm: "",
        xlzsh: "",
        hdxwm: "",
        hdxwmc: "",
        xwzsh: "",
      },
      rules: {
        rxny: [
          {
            required: true,
            message: "请选择入校时间",
            trigger: "change",
          },
        ],
        jsxyny: [
          {
            required: true,
            message: "请选择结束学业时间",
            trigger: "change",
          },
        ],
        gjdqm: [
          {
            required: true,
            message: "请选择国家地区",
            trigger: "change",
          },
        ],
        byyxxhdw: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "change",
          },
        ],
        sxzym: [
          {
            validator: checkZY,
            trigger: "blur",
          },
        ],
        xlm: [
          {
            required: true,
            message: "请输入获得学历",
            trigger: "change",
          },
        ],
        xlzsh: [
          {
            required: true,
            message: "输入学历证书号",
            trigger: "change",
          },
        ],
        hdxwm: [
          {
            validator: checkXW,
            trigger: "blur",
          },
        ],
        xwzsh: [
          {
            validator: checkXWH,
            trigger: "blur",
          },
        ],
        xxfsm: [
          {
            required: true,
            message: "请选择学习方式",
            trigger: "change",
          },
        ],
        xxxsm: [
          {
            required: true,
            message: "请选择学习形式",
            trigger: "change",
          },
        ],
      },
      handleForm: {},
      handleSid: "",
      userInfo: {}
    };
  },
  methods: {
    open(sid) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteUserEdu(sid).then((res) => {
            if (res.status) {
              this.getEdu();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
            this.$message({
              type: "info",
              message: res.message,
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    checkForm() {
      this.$refs["EduactionForm"].validate((valid) => {
        if (valid) {
          updateUserEdu(this.EduactionForm).then((res) => {
            if (res.status) {
              this.showmodal = false;
              getUserEdu(this.$route.query.sfzjh).then((res) => {
                if (res.status) {
                  this.eduList = res.data;
                }
              });
            }
          });
          return false;
        }
      });
    },
    addEdu() {
      for (const key in this.EduactionForm) {
        this.EduactionForm[key] = "";
      }
      this.xwShow = false;
      this.zyShow = false;
      this.showmodal = true;
      this.modalText = "添加学习经历";
      this.modalBtnText = "确定添加";
    },
    editEdu(e) {
      let index = $(e.target).parent().attr("index");
      this.EduactionForm = JSON.parse(JSON.stringify(this.eduList[index]));
      let formData = this.EduactionForm;
      this.model();
      this.xlChange();
      let data = {
        zyList: "sxzym",
      };
      boundDefaultData(this.sjzd, formData, data);
      this.$forceUpdate();
      this.checkXL();
      this.showmodal = true;
      this.modalText = "修改学习经历";
      this.modalBtnText = "确定修改";
    },
    checkXL() {
      this.xwShow = true;
      this.zyShow = true;
      let xlm = this.EduactionForm.xlm;
      let code = Number(xlm[0]);
      if (code == 1) {
        this.sjzd.zyList = this.sjzd.dict_zydm_yjs;
      } else if (code == 2) {
        this.sjzd.zyList = this.sjzd.dict_zydm_bk;
      } else if (code == 3 || code == 4 || code == 4) {
        this.sjzd.zyList = this.sjzd.dict_zydm_zk;
      } else {
        this.sjzd.zyList = [];
        this.zyShow = false;
        this.EduactionForm.sxzym = "";
        this.EduactionForm.sxzymc = "";
      }
      if (code > 3) {
        this.xwShow = false;
        this.EduactionForm.xwzsh = "";
        this.EduactionForm.hdxwm = "";
        this.EduactionForm.hdxwmc = "";
      }
    },
    deleteEdu: function (e) {
      let index = $(e.target).parent().attr("index");
      let sid = this.eduList[index].sid;
      this.open(sid);
      deleteUserEdu(sid).then((res) => {
        if (res.status) {
          this.getEdu();
        }
      });
    },
    changeOption(event, form, kv) {
      changeOption(event, form, kv);
      this.$forceUpdate();
    },
    handleChange(event, form, name, code) {
      handleChange(event, form, name, code);
    },
    changeDate() {
      let _this = this;
      //因为date1和date2格式为 年-月-日， 所以这里先把date1和date2转换为时间戳再进行比较
      let date_start = new Date(_this.EduactionForm.rxny).getTime();
      let date_end = new Date(_this.EduactionForm.jsxyny).getTime();

      _this.pickerOptions_start = {
        disabledDate: (time) => {
          if (date_end != "") {
            return time.getTime() > Date.now() || time.getTime() > date_end;
          } else {
            return time.getTime() > Date.now();
          }
        },
      };
      _this.pickerOptions_end = {
        disabledDate: (time) => {
          return time.getTime() < date_start || time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "至今",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      };
    },
    xlChange(event) {
      if (event) {
        this.changeOption(event, this.EduactionForm, ["xlmc", "xlm"]);
      }

      this.checkXL();
    },
    model() {
      let sjzd = this.sjzd;
      let fromData = this.EduactionForm;
      let data = {
        dict_gjdq: "gjdqm",
        dict_xl: "xlm",
        dict_xw: "hdxwm",
        dict_xxfs: "xxfsm",
        dict_xxxs: "xxxsm",
      };
      boundDefaultData(sjzd, fromData, data);
      console.log(fromData);
      this.$forceUpdate();
    },
    getEdu() {
      getUserEdu(this.$route.query.sfzjh).then((res) => {
        if (res.status) {
          this.eduList = res.data;
        }
      });
    },
    handleEdu(sid, ref){
      this.$refs[ref].show()
      this.handleForm = {}
      this.handleSid = sid
    },
    handle(){
      handleUserEdu(this.handleSid, this.handleForm).then(res=>{
        if(res.status){
          this.$notify({
            title: "成功",
            message: "审核成功",
            type: "success",
          });
          this.$refs['yzmodal1'].hide()
          this.$refs['yzmodal2'].hide()
          this.getEdu()
          this.$refs['personHeader'].init()
        }
      })
    },
    getUserInfo(){
      userInfo(this.$route.query.sfzjh).then((res) => {
        if (res.status) {
          this.userInfo = res.data;
        }
      });
    }
  },
  created() {
    this.getEdu();
    getDictionary(
      "dict_gjdq",
      "dict_xl",
      "dict_xw",
      "dict_xxfs",
      "dict_xxxs",
      "dict_zydm_bk",
      "dict_zydm_yjs",
      "dict_zydm_zk"
    ).then((res) => {
      this.sjzd = res;
    });
    this.changeDate();
    this.getUserInfo()
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" ref="personHeader"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-4">学习经历添加及学历认证</span>
              <span class="mr-2"
                ><i class="iconfont icon-ze-user-o mr-2"></i>姓名</span
              >
              <span class="mr-2">{{ userInfo.xm }}</span>
              <span class="mr-2"
                ><i class="iconfont icon-idcard1e mr-2"></i>身份证号</span
              >
              <span class="mr-2">{{ userInfo.sfzjh }}</span>
            </h4>
            <div class="mb-4">
              <span class="grey-time">请点击学历或学位进行审核</span>
            </div>
            <div class="table-responsive">
              <table class="table mb-0" v-if="eduList">
                <thead class="thead-light">
                  <tr>
                    <th>序号</th>
                    <th>起止时间</th>
                    <th>国家地区</th>
                    <th>学校名称</th>
                    <th>所学专业</th>
                    <th>获得学历</th>
                    <th>学历证书号</th>
                    <th>学历认证情况</th>
                    <th>获得学位</th>
                    <th>学位证书号</th>
                    <th>学位认证情况</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in eduList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.rxny }}至{{ item.jsxyny }}</td>
                    <td>{{ item.gjdqmc }}</td>
                    <td>{{ item.byyxxhdw }}</td>
                    <td>{{ item.sxzymc }}</td>
                    <td><a href="javascript:;" @click="handleEdu(item.sid, 'yzmodal1')" class="blue-font">{{ item.xlmc }}</a></td>
                    <td>
                      <div class="line1">{{ item.xlzsh }}</div>
                    </td>
                    <td>
                      <a href="javascript:;" @click="handleEdu(item.sid, 'yzmodal1')" v-if="item.xlshzt">
                        <div class="text-success" v-if="item.xlshzt == 1">
                          <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                          >人工核验通过
                        </div>
                        <div class="text-danger" v-else-if="item.xlshzt == 2">
                          <i class="iconfont icon-ze-clear text-danger mr-2"></i
                          >{{item.xlshbz}}
                        </div>
                        <div class="text-info" v-else>
                          <i class="iconfont icon-icon_minus-circled mr-2"></i>待人工审核
                        </div>
                      </a>
                      <a href="javascript:;" @click="handleEdu(item.sid, 'yzmodal1')" v-else>
                        <div class="text-success" v-if="item.xlxtrzzt == 1">
                          <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                          >系统核验通过
                        </div>
                        <div class="text-danger" v-else-if="item.xlxtrzzt == 2">
                          <i class="iconfont icon-ze-clear text-danger mr-2"></i
                          >{{item.xlxtrzsm}}
                        </div>
                        <div class="text-info" v-else>
                          <i class="iconfont icon-icon_minus-circled mr-2"></i>待系统审核
                        </div>
                      </a>
                    </td>
                    <td><a href="javascript:;" @click="handleEdu(item.sid,'yzmodal2')" class="blue-font">{{ item.hdxwmc }}</a></td>
                    <td>{{ item.xwzsh }}</td>
                    <td>
                      <a href="javascript:;" @click="handleEdu(item.sid, 'yzmodal2')" v-if="item.xwshzt">
                        <div class="text-success" v-if="item.xwshzt == 1">
                          <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                          >人工核验通过
                        </div>
                        <div class="text-danger" v-else-if="item.xwshzt == 2">
                          <i class="iconfont icon-ze-clear text-danger mr-2"></i
                          >{{item.xwshbz}}
                        </div>
                        <div class="text-info" v-else>
                          <i class="iconfont icon-icon_minus-circled mr-2"></i>待人工审核
                        </div>
                      </a>
                      <a href="javascript:;" @click="handleEdu(item.sid, 'yzmodal2')" v-else>
                        <div class="text-success" v-if="item.xwxtrzzt == 1">
                          <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
                          >系统核验通过
                        </div>
                        <div class="text-danger" v-else-if="item.xwxtrzzt == 2">
                          <i class="iconfont icon-ze-clear text-danger mr-2"></i
                          >{{item.xwxtrzsm}}
                        </div>
                        <div class="text-info" v-else>
                          <i class="iconfont icon-icon_minus-circled mr-2"></i>待系统审核
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a :href="'/admin/personalWork?sfzjh=' + this.$route.query.sfzjh"
              ><button type="button" class="btn btn-info h35 sub-btn">
                学历审定完成，继续下一步
              </button></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 添加学习经历弹窗 -->
    <b-modal
      id="addEducation"
      v-model="showmodal"
      centered
      :title="modalText"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <el-form
        :model="EduactionForm"
        :rules="rules"
        ref="EduactionForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="time_group">
          <el-form-item label="入校时间" prop="rxny" class="w-50">
            <el-date-picker
              v-model="EduactionForm.rxny"
              type="date"
              class="w-100"
              placeholder="入校时间"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_start"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="jsxyny" class="w-50">
            <el-date-picker
              v-model="EduactionForm.jsxyny"
              type="date"
              class="w-100"
              placeholder="结束时间"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_end"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="国家地区" prop="gjdqm">
          <el-select
            v-model="EduactionForm.gjdqm_"
            placeholder="请选择国家地区"
            class="w-100"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['gjdqmc', 'gjdqm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_gjdq"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学校名称" prop="byyxxhdw">
          <el-input
            type="text"
            v-model="EduactionForm.byyxxhdw"
            placeholder="请输入学校名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="获得学历" prop="xlm">
          <el-select
            v-model="EduactionForm.xlm_"
            class="w-100"
            placeholder="请选择学历"
            value-key="name"
            @change="xlChange($event)"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xl"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历证书号" prop="xlzsh">
          <el-input
            type="text"
            v-model="EduactionForm.xlzsh"
            placeholder="输入学历证书号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习方式" prop="xxfsm">
          <el-select
            v-model="EduactionForm.xxfsm_"
            class="w-100"
            placeholder="请选择学习方式"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['xxfsmc', 'xxfsm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xxfs"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习形式" prop="xxxsm">
          <el-select
            v-model="EduactionForm.xxxsm_"
            class="w-100"
            placeholder="请选择学习形式"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['xxxsmc', 'xxxsm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xxxs"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所学专业" prop="sxzym" v-if="zyShow">
          <el-select
            v-model="EduactionForm.sxzym_"
            class="w-100"
            placeholder="请选择学位"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['sxzymc', 'sxzym'])"
          >
            <el-option
              v-for="(v, i) in sjzd.zyList"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="获得学位" prop="hdxwm" v-if="xwShow">
          <el-select
            v-model="EduactionForm.hdxwm_"
            class="w-100"
            placeholder="请选择学位"
            value-key="name"
            @change="changeOption($event, EduactionForm, ['hdxwmc', 'hdxwm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_xw"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学位证书号" prop="xwzsh" v-if="xwShow">
          <el-input
            type="text"
            v-model="EduactionForm.xwzsh"
            placeholder="请输入学位证书号"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-info h30 w-md mr-3"
              @click="checkForm"
            >
              {{ modalBtnText }}
            </button>
            <button
              type="button"
              class="btn btn-secondary h30 w-md"
              @click="showmodal = false"
            >
              取消
            </button>
          </div>
        </el-form-item>
      </el-form>
    </b-modal>
    <!-- 添加学习经历弹窗结束 -->
    <YzModal ref="yzmodal1" title="学历审核" :bound="['xlshzt', 'xlshbz']" v-model="handleForm" @change="handle"></YzModal>
    <YzModal ref="yzmodal2" title="学位审核" :bound="['xwshzt', 'xwshbz']" v-model="handleForm" @change="handle"></YzModal>
  </Layout>
</template>
